// type和初始值initialState
import { CSSProperties } from "react";
import { EIconType } from "@/public/static/interface";
import { Url } from "next/dist/shared/lib/router/router";
import { INotice } from "@/src/components/Footer/cmsAndNoCmsComponents/interface";
import { IConfig } from "@/src/components/Header/Announcement/interface";

interface IImg {
  img: string;
  name: string;
  href: string;
}

interface IText {
  icon: EIconType;
  name: string;
  href: string;
}

export interface INavProps {
  title: string;
  isChild: boolean;
  imgList: IImg[];
  textList: IText[];
  href?: Url;
  children: any[];
}
export interface IPageList {
  name: string;
  id: number;
  sort: number;
  props: IH5Banner | IH5InfoBar | IH5PlainText | IH5Bubble;
}

export interface H5ProductNew {
  title: string;
  productList: IH5ProductNewList[];
}

interface IImgSmg {
  color: string;
  discount_price: string;
  productimg: string;
  color_ordered: number;
  color_qty: number;
  colorIsPrimary: number;
  color_id: number;
  products_id: number;
  price: string;
  color_number: string;
  color_image: string;
  color_name: string;
  productimg1: string;
  color_recent_ordered: number;
  dateCreated?: number;
}
export interface IH5ProductNewList {
  img: number;
  productId: number;
  vistit: number;
  link: string;
  save: boolean;
  check: boolean;
  title: string;
  newProduct: boolean;
  price: string;
  imgsmg: IImgSmg[];
  inputval: string;
  collect: string | number;
  shouimg: boolean;
}

export interface INewBest {
  title: string;
  productList: IH5ProductNewList[];
  shopAll: string;
}
export interface H5Product {
  new: INewBest;
  showbest: boolean;
  best: INewBest;
  shownew: boolean;
  order: string;
}

export interface H5Product1 {
  new: INewBest;
  showbest: boolean;
  best: INewBest;
  shownew: boolean;
  order: string;
  componentUniqueId: string;
}

export interface IBubble {
  img: string;
  link: string;
  title: string;
}
export interface IH5Bubble {
  bubble: IH5Bubble[];
}
export interface IH5PlainText {
  title: string;
  text: string;
}

export interface IH5CommentArea {
  title: string;
  describe:string
  list:any[];
  isAutoplay:boolean;
}


export interface IH5UserRotation {
  arrange:number;
  describe:string;
  isAutoplay:boolean;
  list:any[];
  title: string;
}

export interface IH5ProductRecommendations {
  shopMore: string;
  iscountdown: boolean;
  link: string;
  countdown: string[];
  bgimg: string;
  productShowType: number;
  componentUniqueId: string;
  type: number;
  title: string;
  labelList: any[];
  productNewList: any[];
  bgcolor: string;
  countdownUs: string[];
  isLable: boolean;
  titleColor: string;
  ends: string;
  styles: string;
  labelIdList: any[];
  productList: any[];
  calligraphy: string;
  countdownBackgroundColor: string;
}

export interface IH5Combination {
  type: number;
  isAutoplay: boolean;
  ad_list:any[];
  H5NewAdList:any[];
}

export interface ICircle {
  hide_pages: any[];
  img: string;
  pages: number[];
  href: string;
  visible?: string;
}
export interface PageLayoutState {
  is_cms: boolean;
  nav: INavProps[]; // nav-bar 菜单栏数据
  footer: Record<string, any>;
  bot: Record<string, any>;
  terms: Record<string, any>;
  topNotice: INotice;
  notice: Record<string, any>;
  circle: ICircle;
  pageList: IPageList[];
  advertising: Record<string, any>;
  PcPurchase: Record<string, any>;
  childProdoctDetail: Record<string, any>;
  config: Partial<IConfig> | undefined;
}

export type State = PageLayoutState;

interface IH5BannerCarousel {
  link: string;
  id: number;
  url: string;
}

export interface IH5Banner {
  visible: boolean;
  type: string;
  carousel3: IH5BannerCarousel[];
  carousel2: IH5BannerCarousel[];
  carousel1: IH5BannerCarousel[];
}

export interface IH5InfoBar {
  visible: boolean;
  style: CSSProperties | any;
  communication: {
    link: string;
    title: string;
    url: string;
  }[];
}

export type Action = {
  initPageLayoutInfo: (data: Record<string, any>[]) => void;
  setPageLayoutInfo: (name: string, value: any) => void;
};
export type PageLayoutAction = State & Action;

export const topNotice: INotice = {
  pages: [] as number[],
  hide_pages: [] as any[],
  has_skip_btn: false,
  ad_content: "",
  timeRange: [],
  is_show_close_btn: true,
  is_show_swiper: false,
  ad_list: [] as any[],
  name: "",
  skip_content: "",
  skip_link: "",
  backgroundColor: "",
  has_countdown: false,
  background_image: "",
};
export const initialState: State = {
  is_cms: false,
  nav: [],
  footer: {},
  bot: {},
  terms: {},
  topNotice,
  notice: {},
  circle: {
    hide_pages: [],
    img: "",
    pages: [],
    href: "",
  },
  pageList: [],
  advertising: {},
  PcPurchase: {},
  childProdoctDetail: {},
  config: {}
};
