"use client";
import React, { useState, useEffect, useMemo, Fragment } from "react";
import { CSSProperties, ReactNode } from "react";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import stl from "./Slider.module.scss";
import _ from "lodash";
import FmIcon from "@/ui-component/basic/FmIcon";
import classnames from "classnames";

interface IProps<T> {
    dots?: boolean; // 展示dot
    arrow?: boolean;
    sliders: T[];
    renderItem: (item: T, index: number) => ReactNode; // 渲染节点
    arrowProps?: {
        left: {
            disabledIndex?: number;
            className?: string;
        };
        right: {
            disabledIndex?: number;
            className?: string;
        };
    };
    itemWidth?: number;
    slidesConfig?: {
        initial?: number;
        loop?: boolean;
        slides?: {
            origin?: "center" | "auto" | number;
            number?: number | (() => number | null) | null;
            perView?: "auto" | number | (() => number | "auto");
            spacing?: number | (() => number);
        };
        drag?: boolean;
    }; //WebOptions<KeenSliderOptions<{}, {}, KeenSliderHooks>>
    sliderPlugins?: ((slider: any) => any)[];
    style?: CSSProperties;
    className?: string;
    type?: "rem" | "px";
    isAutoplay?: any;
    sliderStyle?: CSSProperties;
    sliderWrapperStyle?: CSSProperties;
    dotsStyle?: {
        dots?: {
            className?: string;
            style?: CSSProperties;
        };
    };
}

interface IArrowProps {
    disabled: boolean;
    onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    left?: boolean;
    right?: boolean;
    className?: string;
}

const defaultProps = {
    type: "px",
    dots: false,
    arrow: false,
    arrowProps: {
        left: {
            disabledIndex: 0,
            className: "",
        },
        right: {
            disabledIndex: 1,
            className: "",
        },
    },
    slidesConfig: {
        initial: 1,
        loop: false,
        slides: {
            perView: 1,
            spacing: 10,
        },
    },
};

const Arrow = (props: IArrowProps) => {
    return (
        <div
            onClick={props.onClick}
            className={classnames(
                stl["arrow"],
                {
                    [stl["arrow--left"]]: props.left,
                    [stl["arrow--right"]]: !props.left,
                    [stl["arrow--disabled"]]: props.disabled,
                },
                props.className,
            )}
        >
            {props.left && (
                <FmIcon
                    icon="icon--arrow-left"
                    className={classnames(stl["arrow--icon"])}
                ></FmIcon>
            )}
            {!props.left && (
                <FmIcon
                    icon="icon--arrow-right"
                    className={classnames(stl["arrow--icon"])}
                ></FmIcon>
            )}
        </div>
    );
};

const deepClone = (obj: Record<string, any>) => {
    try {
        return JSON.parse(JSON.stringify(obj));
    } catch {
        return obj;
    }
};

const deepMerge = (
    target: Record<string, any>,
    source: Record<string, any>,
) => {
    for (const key in source) {
        if (
            source[key] &&
            typeof source[key] === "object" &&
            !Array.isArray(source[key])
        ) {
            // 如果目标中不存在这个属性，则初始化为一个空对象
            if (!target[key]) {
                target[key] = {};
            }
            // 递归合并
            deepMerge(target[key], source[key]);
        } else {
            // 否则直接覆盖
            target[key] = source[key];
        }
    }
    return target;
};

const Slider = <T,>(prop: IProps<T>) => {
    const {
        sliders,
        dots,
        isAutoplay,
        arrow,
        renderItem,
        arrowProps,
        slidesConfig,
        dotsStyle,
        ...props
    } = deepMerge(deepClone(defaultProps), prop);
    const [currentSlide, setCurrentSlide] = useState(slidesConfig.initial);
    useEffect(() => {
        setCurrentSlide(slidesConfig.initial);
    }, [slidesConfig.initial]);
    const [loaded, setLoaded] = useState(false);

    const { slides, ...restSlidesConfig } = useMemo(
        () => slidesConfig,
        [slidesConfig],
    );
    const [sliderRef, instanceRef] = useKeenSlider(
        {
            // initial: 1, // 从1开始展示，也是默认值
            // slides: {
            //   perView: 3, // 每页展示3条数据
            //   spacing: 15, // 间距15px
            //   origin: 'center' // 居中展示
            // },
            ...restSlidesConfig,
            slides: {
                ...slides,
                spacing:
                    props.type === "rem" && typeof slides.spacing === "number"
                        ? slides.spacing * 100
                        : slides.spacing,
            },
            slideChanged(slider: any) {
                setCurrentSlide(slider.track.details.rel);
            },
            created() {
                setLoaded(true);
            },
        },
        isAutoplay && [
            (slider) => {
                let timeout: any = null;
                let mouseOver = false;
                function clearNextTimeout() {
                    clearTimeout(timeout);
                }
                function nextTimeout() {
                    clearTimeout(timeout);
                    if (mouseOver) return;
                    timeout = setTimeout(() => {
                        slider?.next();
                    }, 3000);
                }
                slider.on("created", () => {
                    slider.container.addEventListener("mouseover", () => {
                        mouseOver = true;
                        clearNextTimeout();
                    });
                    slider.container.addEventListener("mouseout", () => {
                        mouseOver = false;
                        nextTimeout();
                    });
                    nextTimeout();
                });
                slider.on("dragStarted", clearNextTimeout);
                slider.on("animationEnded", nextTimeout);
                slider.on("updated", nextTimeout);
            },
        ],
    );

    const sliderWidth = useMemo(() => {
        if (
            props.itemWidth &&
            slidesConfig &&
            typeof slidesConfig.slides === "object" &&
            typeof slidesConfig.slides.perView === "number" &&
            typeof slidesConfig.slides.spacing === "number"
        ) {
            return `${slidesConfig.slides.perView * props.itemWidth + (slidesConfig.slides.perView - 1) * slidesConfig.slides.spacing}${props.type}`;
        }
        return "unset";
    }, []);

    const handleArrowLeft = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
        e.stopPropagation();
        instanceRef.current && instanceRef.current.prev();
    };
    return (
        <div
            className={`Slider component-slider-wrapper ${props.className}`}
            style={{
                margin: "0 auto",
                width: "100%",
                maxWidth: "1440px",
                ...props.style,
            }}
        >
            <div
                className="navigation-wrapper"
                style={props.sliderWrapperStyle}
            >
                <div
                    ref={sliderRef}
                    className="keen-slider"
                    style={{
                        width: sliderWidth,
                        "--slider-wrapper-width": sliderWidth,
                        margin: "0 auto",
                        ...props.sliderStyle,
                        flexWrap: "nowrap",
                    }} // 单条item宽度 * 3 + 间距 * 2
                >
                    {sliders.map((item: T, index: number) => {
                        return renderItem(
                            {
                                ...item,
                                className:
                                    "keen-slider__slide added-item-wrapper",
                            },
                            index,
                        );
                    })}
                </div>
                {arrow && loaded && instanceRef.current && (
                    <Fragment>
                        <Arrow
                            left
                            onClick={handleArrowLeft}
                            disabled={
                                !slidesConfig.loop &&
                                currentSlide === arrowProps.left.disabledIndex
                            }
                            className={arrowProps.left.className}
                        />

                        <Arrow
                            onClick={(
                                e: React.MouseEvent<HTMLDivElement, MouseEvent>,
                            ) => {
                                e.stopPropagation();
                                if (!instanceRef.current) {
                                    return;
                                }
                                if (
                                    !slidesConfig.loop &&
                                    currentSlide ===
                                        instanceRef.current.slides.length -
                                            arrowProps.right.disabledIndex
                                ) {
                                    return;
                                }
                                instanceRef.current.next();
                            }}
                            disabled={
                                !slidesConfig.loop &&
                                currentSlide ===
                                    instanceRef.current.slides.length -
                                        arrowProps.right.disabledIndex
                            }
                            className={arrowProps.right.className}
                        />
                    </Fragment>
                )}
            </div>
            {/* 控制showDots确认是否展示dot节点（这个还没调过） */}
            {dots && loaded && instanceRef.current && (
                <div
                    className={classnames(
                        stl["dots"],
                        dotsStyle?.dots?.className,
                    )}
                    style={dotsStyle && dotsStyle.dots && dotsStyle.dots.style}
                >
                    {[
                        ...Array(
                            instanceRef.current.track?.details?.slides?.length,
                        ).keys(),
                    ].map((idx) => {
                        return (
                            <button
                                key={idx}
                                onClick={() => {
                                    instanceRef.current &&
                                        instanceRef.current.moveToIdx(idx);
                                }}
                                className={classnames(
                                    stl["dot"],
                                    {
                                        [stl["dot-active"]]:
                                            currentSlide === idx,
                                    }
                                )}
                            ></button>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default Slider;
