"use client";
import React from "react";
import stl from "./SwiperCms.module.scss";
import classnames from "classnames";
import Slider from "../../Slider";
import { TopNoticeContent } from "../Announcement/Server";
import AddGaTopAdClickClient from "../../gtagFunctionComponent/AddGaTopAdClickClient";
import { FmLink } from "@/ui-component";

interface IProps {
    ad_list: any[];
    isAutoplay: boolean;
}

const SwiperCms = ({ ad_list, isAutoplay }: IProps) => {
    const renderItem = (item: any, index: number) => {
        const { className, ...rest } = item;
        return (
            <div
                key={index}
                className={item.className}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "0.26rem 0.2rem",
                }}
            >
                <TopNoticeContent {...rest}>
                    <AddGaTopAdClickClient>
                        <FmLink
                            className={stl["link"]}
                            href={item.skip_link}
                            style={{ color: item.textColor }}
                        >
                            {item.skip_content}
                        </FmLink>
                    </AddGaTopAdClickClient>
                </TopNoticeContent>
            </div>
        );
    };

    return  (
        <div className={classnames(stl["swiper-cms-wrapper"])}>
            <Slider
                renderItem={renderItem}
                sliders={ad_list}
                isAutoplay={isAutoplay}
                itemWidth={7.5}
                type={"rem"}
                arrow
                slidesConfig={{
                    loop: true,
                    initial: 0,
                }}
            ></Slider>
        </div>
    );
};

export default SwiperCms;
