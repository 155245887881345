import { create } from "zustand";
import { PageLayoutAction, initialState } from "./initialState";
import { IConfig } from "@/src/components/Header/Announcement/interface";
export type PageLayoutStore = ReturnType<typeof createPageLayoutStore>;
export const createPageLayoutStore = (pageData?: any, config?: Partial<IConfig>) => {
  const init = { ...initialState, ...initData(pageData), config };
  return create<PageLayoutAction>()((set, get) => ({
    ...init,
    initPageLayoutInfo(data) {
      set(initData(data));
    },
    setPageLayoutInfo(name, value) {
      set({ [name]: value });
    },
  }));
};

function checkItem(item: any, name: string, is_child: boolean) {
  if (is_child) {
    if (item.name == name && item.is_child) {
      return item;
    }
  } else if (item.name == name) {
    return item;
  }
}

export function getGlobalCompoentData(
  data: any[],
  name: string,
  is_child = false,
) {
  let component = data.filter((item) => checkItem(item, name, is_child))[0];
  if (!component) {
    return {};
  }

  return component.props || {};
}

function initData(data: any[] = []) {
  //数组为空的时候不展示cms
  if (!data?.length) {
    return {
      is_cms: false,
    };
  }
  // let footer = {};
  // let terms = {};
  // let pageList = [];
  // let bot = {};
  // let PcPurchase = {};

  //移动
  const nav = getGlobalCompoentData(data, "H5Nav").nav || [];
  // const notice = getGlobalCompoentData(data, "H5Notice");
  // const topNotice =
  //     getGlobalCompoentData(data, "H5TopNotice") || initTopNotice;
  // terms = getGlobalCompoentData(data, "H5Terms");
  // footer = getGlobalCompoentData(data, "H5Footer");
  // bot = getGlobalCompoentData(data, "H5ShareIcon");
  // const circle = getGlobalCompoentData(data, "H5Circle");
  // const advertising = getGlobalCompoentData(data, "H5Advertising", true);
  // const childProdoctDetail = getGlobalCompoentData(
  //     data,
  //     "H5ChildDetails",
  //     true,
  // );

  return {
    nav,
    // topNotice,
    // notice,
    // terms,
    // footer,
    // pageList: data,
    // bot,
    // circle,
    // advertising,
    // PcPurchase,
    // childProdoctDetail,
  };
}