"use client";
import React, {
    useState,
    useCallback,
    useEffect,
    useMemo,
    useRef,
} from "react";
import { FmIcon } from "@/ui-component";
import classnames from "classnames";
import stl from "./styles/notice.module.scss";
import { getSession, setSession } from "@/lib/commonService";
import { pageType } from "@/config/constConf";
import { IAnnouncementProps } from ".";
import { headerStore } from "@/store/pageModules";
import { usePageLayoutContext } from "@/src/provider";
const NOT_SHOW_SESSION_KEY = "NOT_SHOW_SESSION_KEY";
const NOT_SHOW_SESSION_VAL = "1";
export interface IChildrenProps extends IAnnouncementProps {}
interface IProps extends IAnnouncementProps {}

const Client: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { topNotice, page_name } = props;
    const anouncementRef = useRef<HTMLDivElement>(null);
    const [show, setShow] = useState(true);
    const [showTopNoticeNextTick, setShowTopNoticeNextTick] = useState(false);
    const [setHeaderOptions] = headerStore.useHeaderStore((state) => [
        state.setHeaderOptions,
    ]);
    const [config] = usePageLayoutContext((state) => [state.config]);
    const checkDataShow = useCallback(() => {
        let hidePage = [];
        let showPage = [];

        showPage = config?.topNotice
            ? config?.topNotice.show_pages
            : topNotice.pages;
        hidePage = config?.topNotice
            ? config?.topNotice.hide_pages
            : topNotice.hide_pages;

        const pathName = location.pathname;
        if (getSession(NOT_SHOW_SESSION_KEY)) {
            setShow(false);
            return;
        }
        //显示页面
        let _pageType = pageType;

        if (!showPage.includes(_pageType[page_name]) && !showPage.includes(0)) {
            setShow(false);

            return;
        }

        //隐藏页面
        hidePage.forEach((item: any) => {
            if (item.value === pathName) {
                setShow(false);
            }
        });
    }, [topNotice, page_name, config]);

    // 首次进入，校验是否显示
    useEffect(() => {
        checkDataShow();
        props.onScroll?.();
        setShowTopNoticeNextTick(true);
        if (anouncementRef.current) {
            setHeaderOptions([
                {
                    anouncementHeaderHeight:
                        anouncementRef.current?.clientHeight,
                },
            ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onClose = useCallback(() => {
        setShow(false);
        setSession(NOT_SHOW_SESSION_KEY, NOT_SHOW_SESSION_VAL);
    }, []);

    const cmsNoticeBg = useMemo(() => {
        return {
            background:
                topNotice.backgroundColor ||
                (topNotice.background_image
                    ? `url(${topNotice.background_image}) no-repeat center center`
                    : "#000"),
        };
    }, [topNotice.backgroundColor, topNotice.background_image]);

    return (
        <>
            {show && showTopNoticeNextTick ? (
                <div
                    style={cmsNoticeBg}
                    className={classnames(
                        stl["top-notice-container"],
                        "top-notice-container",
                        {
                            [stl["top-notice-container-padding"]]:
                                !topNotice.is_show_swiper,
                        },
                        props.className,
                    )}
                    ref={anouncementRef}
                >
                    {!topNotice.is_show_swiper ? (
                        props.children
                    ) : (
                        <div
                            className={classnames(
                                stl["carousel-container"],
                                "carousel-container",
                            )}
                        >
                            {props.children}
                        </div>
                    )}
                    <FmIcon
                        icon="icon--close"
                        className={classnames(
                            stl["close-icon"],
                            stl["top-notice-close"],
                            {
                                ["hidden"]: !topNotice.is_show_close_btn,
                            },
                        )}
                        onClick={onClose}
                    />
                </div>
            ) : null}
        </>
    );
};

export default Client;
