"use client";
import React, { useMemo } from "react";
import { pageType } from "@/config/constConf";
import { IProps } from ".";
import { usePathname } from "next/navigation";
import { usePageLayoutContext } from "@/src/provider";

const BottomNoticeClient: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const { H5Notice, page_name = "all" } = props;
    const [config] = usePageLayoutContext((state) => [state.config]);
    const { pages = [], hide_pages = [] } = H5Notice;
    const pathname = usePathname();
    
    const show = useMemo(() => {
        let hidePage = [];
        let showPage = [];
        showPage = config?.notice
            ? config?.notice.show_pages
            : pages;
        hidePage = config?.notice
            ? config?.notice.hide_pages
            : hide_pages;
        // 检查当前路径是否在隐藏页面列表中
        const isHidden = hidePage.some((item:any) => item.value === pathname);

        const __pageType: any = pageType;
        // 获取当前页面类型，假设pageType是一个对象，直接访问键值可能更清晰
        const currentPageType = __pageType[page_name] || null;

        // 检查页面类型是否允许显示，考虑了pages中可能包含0作为默认允许的情况
        const isAllowedPageType =
            showPage.includes(currentPageType) || showPage.includes(0);

        // 页面不应显示，如果它被隐藏，或者页面类型不在允许的列表中
        return !isHidden && isAllowedPageType;
    }, [hide_pages, page_name, pages, pathname, config]);

    return show && props.children;
};

export default BottomNoticeClient;
