"use client";
import {
    PageLayoutStore,
    createPageLayoutStore,
} from "@/store/modules/pageLayout";
import React, { createContext, useRef } from "react";
export const PageLayoutContext = createContext<PageLayoutStore | null>(null);

interface PageLayoutProviderProps extends React.PropsWithChildren {
    pageData?: any[];
    config?: Record<string, any>;
}

export function PageLayoutProvider({
    children,
    pageData,
    config,
}: PageLayoutProviderProps) {
    const storeRef = useRef<PageLayoutStore>();
    if (!storeRef.current) {
        storeRef.current = createPageLayoutStore(pageData, config);
    }
    return (
        <PageLayoutContext.Provider value={storeRef.current}>
            {children}
        </PageLayoutContext.Provider>
    );
}
